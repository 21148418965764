<template>
  <div class="app-wrapper dashboard-blk">
    <div class="app-column">
      <div class="app-welcome-screen">
        <transition name="fade">
          <template v-if="isLoggedIn">
            <div class="app-time-widgets">
              <div class="time-widgets-txt dashboard-time">
                <DashTime></DashTime>
              </div>
              <div
                @click="goToEmployeePage()"
                class="time-widgets-icon usr-clock-icn"
              >
                <font-awesome-icon icon="user-clock" />
              </div>
              <div class="time-widgets-icon">
                <img src="../assets/img/logo-neat.svg" alt="" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="app-time-widgets">
              <div>
                <img
                  class="form-logo"
                  src="../assets/img/logo-kiosk-login.png"
                  alt=""
                />
              </div>
              <div class="login-form">
                <form class="login-form" @submit.prevent="openKiosk">
                  <InputText
                    class="form-input"
                    type="number"
                    :placeholder="$t('accountId')"
                    v-model="accountId"
                  />
                  <InputText
                    class="form-input"
                    type="text"
                    :placeholder="$t('kioskCode')"
                    v-model="kioskCode"
                    autocapitalize="off"
                  />
                  <Button type="submit" class="primary-btn" @click="openKiosk">
                    {{ $t('openKiosk') }}&nbsp;
                    <font-awesome-icon
                      v-if="isLoading"
                      class="fa-spin"
                      icon="spinner"
                    />
                  </Button>
                </form>
              </div>
              <div>
                <img
                  class="form-logo"
                  src="../assets/img/kiosk-text-login.png"
                  alt=""
                />
              </div>
            </div>
          </template>
        </transition>
      </div>
    </div>
    <transition name="fade">
      <div v-if="isLoggedIn" @click="goToLogout" class="app-closer">
        <font-awesome-icon icon="sign-out-alt" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  onBeforeUnmount,
  onMounted,
  getCurrentInstance,
} from 'vue';
import { useStore } from '../store';
import { format } from '../shared/date.helper';
import DashTime from '../components/DashTime.vue';
import { fetchConfig, fetchEmployees } from '../shared/kiosk.helper';
import { AxiosStatic } from 'axios';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  name: 'Dashboard',
  components: { DashTime },
  setup() {
    const axios = <AxiosStatic>getCurrentInstance()?.appContext?.app?.axios;
    const i18n = useI18n();
    const store = useStore();

    const storedAccountId =
      store.state.accountId || localStorage.getItem('accountId');
    const storedKioskShortId =
      store.state.kioskId || localStorage.getItem('kioskShortId');
    const isLoggedIn = ref(!!storedAccountId && !!storedKioskShortId);

    const isLoading = ref(false);
    const accountId = ref('');
    const kioskCode = ref('');

    const now = ref(new Date());
    const currentDate = ref(format(now.value, 'eeee, dd MMMM'));
    const currentTime = ref(format(now.value, 'HH:mm'));

    const toast = useToast();

    const updateCurrentTime = () => {
      currentDate.value = format(new Date(), 'eeee, dd MMMM');
      currentTime.value = format(new Date(), 'HH:mm');
    };

    const openKiosk = async () => {
      if (!accountId.value || !kioskCode.value || isLoading.value) {
        return;
      }
      isLoading.value = true;
      store.commit('setAccountId', accountId.value);
      const validated = await fetchConfig(kioskCode.value, axios, store, i18n);

      if (!validated || validated === 'server-error') {
        isLoading.value = false;
        return;
      }

      localStorage.setItem('accountId', accountId.value);
      localStorage.setItem('kioskShortId', kioskCode.value);
      isLoggedIn.value = true;
      isLoading.value = false;
      fetchEmployees(validated, axios, store);
    };

    const logout = () => {
      isLoggedIn.value = false;
      isLoading.value = false;
      localStorage.clear();
    };

    const intervalHandle = setInterval(updateCurrentTime, 1000);
    onBeforeUnmount(() => {
      clearInterval(intervalHandle);
    });

    const name = computed((): string => {
      return store.getters.getName;
    });

    return {
      isLoggedIn,
      isLoading,
      currentDate,
      currentTime,
      name,
      intervalHandle,
      accountId,
      kioskCode,
      openKiosk,
      logout,
    };
  },
  methods: {
    goToEmployeePage() {
      this.$router.push({
        name: 'EmployeePage',
      });
    },
    goToLogout() {
      this.$router.push({
        name: 'LogoutPage',
      });
    },
  },
});
</script>

<style lang="scss">
.login-form {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 15px;
  }

  .primary-btn {
    background-color: #a0d567;
    border-color: #a0d567;
    width: fit-content;
    margin: 0 auto;
    padding: 10px;
    height: 58px;
    color: white;
    font-weight: bold;
    font-size: 25px;

    &:enabled:hover {
      background: #a0d567;
      border-color: #a0d567;
      outline: none;
    }

    span {
      font-weight: bold;
    }
  }
}

.app-loader {
  position: absolute;
  display: flex;
  margin: 0 auto;
  font-size: 80px;
  color: white;
}

.form-logo {
  width: 50% !important;
}

.form-input {
  height: 58px;
  font-size: 25px !important;
}
</style>
