import { createI18n, I18n } from 'vue-i18n';
import { nextTick } from 'vue';

const SUPPORTED_LOCALES = [
  'nl-NL',
  'en-GB',
  'de-DE',
  'fr-FR',
  'es-ES',
  'pl-PL',
  'sv-SE',
  'ro-RO',
];
export const DEFAULT_LOCALE = 'en-GB';

export const LANGUAGE_LOCALE_MAP: {
  [key: string]: string;
} = {
  en: 'en-GB',
  nl: 'nl-NL',
  de: 'de-DE',
  fr: 'fr-FR',
  es: 'es-ES',
  pl: 'pl-PL',
  sv: 'sv-SE',
  ro: 'ro-RO',
};

export function setupI18n(
  messages: any,
  options = {
    locale: DEFAULT_LOCALE,
    fallbackLocale: DEFAULT_LOCALE,
    globalInjection: true,
    legacy: false,
  },
): I18n {
  const i18n: any = createI18n({ ...options, messages });
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export function setI18nLanguage(i18n: any, locale: string) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document?.querySelector('html')?.setAttribute('lang', locale);
}

export async function loadMessages() {
  const messages: any = {};
  for (const locale of SUPPORTED_LOCALES) {
    try {
      const message = await import(
        /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
      );
      messages[locale] = message;
    } catch (e) {
      console.error(e);
    }
  }

  return messages;
}

export async function loadLocaleMessages(
  i18n: any,
  locale: string,
  global: boolean = false,
) {
  // load locale messages with dynamic import

  try {
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
    );

    // set locale and locale message
    if (global) {
      i18n.global.setLocaleMessage(locale, messages);
    } else {
      i18n.setLocaleMessage(locale, messages);
    }
  } catch (e) {
    if (locale !== 'en-GB') {
      await loadLocaleMessages(i18n, 'en-GB');
    }
  }

  return nextTick();
}
