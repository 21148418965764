<template>
  <div class="app-employee-list-blk" style="background-color: white">
    <div class="app-employee-list-head">
      <h4>{{ currentGroup }}</h4>
    </div>
    <div class="app-employee-list-items scroll" @scroll="scroll">
      <div
        v-for="(group, name) in employeeGroups"
        :key="name"
        class="app-employee-group"
      >
        <h4 :ref="'header-' + name">{{ name }}</h4>
        <ul>
          <li
            v-for="employee in group"
            :key="employee.id"
            @click="$emit('employeeSelected', employee)"
          >
            <EmployeeRow :employee="employee"></EmployeeRow>
          </li>
        </ul>
      </div>
    </div>
    <div class="app-employee-list-sort-keyword">
      <ul>
        <li v-for="letter in firstLetters" :key="letter">
          <button @click="goToGroup(letter)" type="button">{{ letter }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import EmployeeRow from './EmployeeRow.vue';
import { Dictionary, useStore } from '../store';
import { EmployeeModel } from '../models/employee.model';
import { debounce } from 'lodash-es';

export default defineComponent({
  name: 'EmployeeList',
  components: {
    EmployeeRow,
  },
  emits: ['employeeSelected'],
  setup() {
    const store = useStore();
    const employeeGroups = computed((): Dictionary<EmployeeModel[]> => {
      return store.getters.getEmployeesGroupedByFirstLetter;
    });
    const currentGroup = ref('');

    const firstLetters = computed((): string[] => {
      const firstLetters = store.getters.getFirstLetter;
      currentGroup.value = firstLetters[0];
      return firstLetters;
    });

    const debounceScrollEvent = debounce((event: Event) => {
      const children: HTMLCollection = event.target.children;
      let topVisible = null;
      const scrollTop = event.target.scrollTop + 59;
      for (const child of children) {
        if (child.offsetTop > scrollTop) {
          break;
        }
        topVisible = child;
      }

      currentGroup.value = topVisible.firstChild.innerText;
    }, 200);

    return { employeeGroups, firstLetters, currentGroup, debounceScrollEvent };
  },
  methods: {
    goToGroup(key: string) {
      const ref = (<any>this.$refs['header-' + key])?.[0];

      if (!ref) return;

      ref.scrollIntoView({
        behavior: 'smooth',
      });
    },
    scroll(event: Event) {
      this.debounceScrollEvent(event);
    },
  },
});
</script>

<style></style>
