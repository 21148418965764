import 'core-js/stable';
import { createApp } from 'vue';
import App from './App.vue';
import './index.scss';
import './sass/main.scss';

import router from './routes';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import 'es6-promise/auto';
import VueAxios from 'vue-axios';
import { key, store } from './store';
import { loadMessages, setupI18n } from './i18n';

import PrimeVue from 'primevue/config';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/bootstrap4-light-purple/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import { AppConfigModel } from './models/app-config.model';
import {
  createAxiosInstance,
  errorInterceptor,
  requestInterceptor,
} from './shared/axios.helper';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { I18n } from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import axiosRetry from 'axios-retry';

library.add(faUserClock);
library.add(faArrowLeft);
library.add(faSyncAlt);
library.add(faSpinner);
library.add(faBackspace);
library.add(faSignOutAlt);
library.add(faExclamationTriangle);
const app = createApp(App);

axios.get('/app-config.json').then(async (response) => {
  const appConfig: AppConfigModel = await response?.data;
  app.config.globalProperties = {
    appConfig: { ...appConfig },
  };

  const localeMessages = await loadMessages();
  const i18n = setupI18n(localeMessages);

  startUpApp(createAxiosInstance(appConfig), i18n);
});

export const startUpApp = (axios: AxiosInstance, i18n: I18n) => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
  });
  axiosRetry(axios, { retries: 5, retryDelay: axiosRetry.exponentialDelay });

  app
    .use(router)
    .use(PrimeVue, { ripple: true })
    .use(ToastService)
    .component('Toast', Toast)
    .component('InputText', InputText)
    .component('Button', Button)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(i18n)
    .use(store, key)
    .use(VueAxios, axios)
    .mount('#app');

  const toast = app.config.globalProperties.$toast;

  axios.interceptors.request.use(requestInterceptor(store));
  axios.interceptors.response.use((response: AxiosResponse) => {
    return response;
  }, errorInterceptor(i18n, toast));
};
