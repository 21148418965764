<template>
  <div class="app-wrapper">
    <div class="app-column welcome-clmn">
      <div class="app-welcome-screen">
        <div class="app-time-widgets employee-time-widget">
          <div class="time-widgets-txt dash-time">
            <DashTime></DashTime>
          </div>
          <div
            class="time-widgets-icon usr-clock-icn back-icon"
            @click="returnToEmployeePage()"
          >
            <font-awesome-icon icon="arrow-left" />
          </div>
          <div class="time-widgets-icon" style="margin-bottom: -5px">
            <a href="https://www.shiftbase.com/" target="_blank"
              ><img src="../assets/img/logo-neat.svg" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="app-column">
      <transition v-bind:name="transitionName">
        <EmployeeClocked
          v-if="clockStatus"
          :clock-status="clockStatus"
          :employee="selectedEmployee"
        ></EmployeeClocked>
        <EmployeeList
          v-else-if="!selectedEmployee"
          @employeeSelected="selectEmployee($event)"
        ></EmployeeList>
        <EmployeePin
          v-else-if="selectedEmployee && selectedEmployee.has_credentials"
          :selected-employee="selectedEmployee"
          @clocked="employeeClocked($event)"
        ></EmployeePin>
        <EmployeeNoCredentials
          v-else
          :selected-employee="selectedEmployee"
          @employeeValidRefresh="employeeRefreshed()"
        ></EmployeeNoCredentials>
      </transition>
    </div>
    <div v-if="!hideButton" class="app-closer">
      <svg
        @click="returnToEmployeePage()"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import EmployeeList from '../components/EmployeeList.vue';
import DashTime from '../components/DashTime.vue';
import EmployeePin from '../components/EmployeePin.vue';
import { EmployeeModel } from '../models/employee.model';
import { ClockModel } from '../models/clock.model';
import EmployeeClocked from '../components/EmployeeClocked.vue';
import EmployeeNoCredentials from '../components/EmployeeNoCredentials.vue';

export default defineComponent({
  name: 'EmployeePage',
  components: {
    EmployeeClocked,
    DashTime,
    EmployeeList,
    EmployeePin,
    EmployeeNoCredentials,
  },
  setup() {
    const selectedEmployee = ref(undefined);
    const clockStatus = ref(undefined);
    const timeoutId = ref(null);
    const hideButton = ref(false);
    const transitionName = ref('slow-transition');

    return {
      clockStatus,
      selectedEmployee,
      timeoutId,
      transitionName,
      hideButton,
    };
  },
  methods: {
    selectEmployee(employee: EmployeeModel) {
      this.transitionName = 'slide-in-left';
      this.selectedEmployee = employee;
    },
    employeeClocked(clockStatus: ClockModel) {
      this.transitionName = 'slide-in-left';
      this.clockStatus = clockStatus;
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (this.clockStatus) {
          this.returnToEmployeePage();
        }
      }, 5000);
    },
    returnToEmployeePage() {
      if (!this.selectedEmployee && !this.clockStatus) {
        this.hideButton = true;

        this.$router.push({
          name: 'Dashboard',
        });
        return;
      }
      this.transitionName = 'slide-out-right';
      this.selectedEmployee = undefined;
      this.clockStatus = undefined;
    },
    employeeRefreshed() {
      if (!this.selectedEmployee) {
        return;
      }

      this.selectedEmployee.has_credentials = true;
    },
  },
});
</script>

<style></style>
