import { format as formatDate, isValid } from 'date-fns';
import {
  de as deDateLocale,
  enGB as enGBDateLocale,
  es as esDateLocale,
  fr as frDateLocale,
  nl as nlDateLocale,
  pl as plDateLocale,
  ro as roDateLocale,
  sv as svDateLocale,
} from 'date-fns/locale';
import { DEFAULT_LOCALE } from '../i18n';

export const dateLocales: any = {
  'en-GB': enGBDateLocale,
  'nl-NL': nlDateLocale,
  'de-DE': deDateLocale,
  'fr-FR': frDateLocale,
  'es-ES': esDateLocale,
  'pl-PL': plDateLocale,
  'sv-SE': svDateLocale,
  'ro-RO': roDateLocale,
};

export function format(
  date: Date | String,
  formatString: string = 'P',
): string {
  if (!isValid(date)) {
    return '';
  }

  const dateLocale =
    dateLocales[localStorage.getItem('locale') ?? DEFAULT_LOCALE];

  return formatDate(date as Date, formatString, { locale: dateLocale });
}
