<template>
  <h2 class="app-time">{{ currentTime }}</h2>
  <h4 class="app-date">
    {{ currentDate }} <span>{{ kioskName }}</span>
  </h4>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue';
import { format } from '../shared/date.helper';
import { useStore } from '../store';
import { upperFirst } from 'lodash-es';

export default defineComponent({
  name: 'DashTime',
  setup() {
    const store = useStore();
    const kioskName = computed((): Date | null => {
      return store.getters.getName;
    });
    const currentDate = ref(upperFirst(format(new Date(), 'EEEE, dd MMMM')));
    const currentTime = ref(upperFirst(format(new Date(), 'HH:mm')));

    const updateCurrentTime = () => {
      currentDate.value = upperFirst(format(new Date(), 'EEEE, dd MMMM'));
      currentTime.value = upperFirst(format(new Date(), 'HH:mm'));
    };

    const intervalHandle = setInterval(updateCurrentTime, 1000);
    onBeforeUnmount(() => {
      clearInterval(intervalHandle);
    });

    return { currentDate, currentTime, kioskName };
  },
});
</script>

<style scoped></style>
