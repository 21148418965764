<template>
  <router-view v-slot="{ Component, route }" class="child-view">
    <transition :name="route.meta.transition || 'fade'">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
} from 'vue';
import { fetchConfig, fetchEmployees } from '../shared/kiosk.helper';
import { useStore } from '../store';
import { AxiosStatic } from 'axios';
import { useI18n } from 'vue-i18n';
import { DEFAULT_LOCALE } from '../i18n';

export default defineComponent({
  name: 'Home',
  setup() {
    const axios = <AxiosStatic>getCurrentInstance()?.appContext?.app?.axios;
    const store = useStore();
    const i18n = useI18n();

    const logout = () => {
      localStorage.clear();
    };

    const lang =
      localStorage.getItem('locale') || navigator.language || DEFAULT_LOCALE;
    i18n.locale.value = lang;

    onMounted(async () => {
      const accountId = localStorage.getItem('accountId');
      const kioskId = localStorage.getItem('kioskShortId');
      if (!accountId || !kioskId) {
        return;
      }

      store.commit('setAccountId', accountId);

      const validated = await fetchConfig(kioskId, axios, store, i18n);
      if (validated) {
        await fetchEmployees(validated, axios, store);
        return;
      }

      if (validated !== 'server-error') {
        logout();
      }
    });

    const intervalHandle = setInterval(() => {
      const accountId = store.state.accountId;
      const kioskId = store.state.kioskId;
      if (!accountId || !kioskId) {
        return;
      }
      fetchEmployees(kioskId, axios, store);
    }, 60000);

    onBeforeUnmount(() => {
      clearInterval(intervalHandle);
    });
  },
});
</script>

<style></style>
