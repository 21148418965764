import { createRouter, createWebHistory } from 'vue-router';
import Home from './pages/Home.vue';
import EmployeePage from './pages/EmployeePage.vue';
import Error from './pages/Error.vue';
import DashboardPage from './pages/DashboardPage.vue';
import LogoutPage from './pages/LogoutPage.vue';

const routes = [
  {
    path: '',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: DashboardPage,
        meta: { transition: 'dash-slide-out-right' },
      },
      {
        path: 'employees',
        name: 'EmployeePage',
        component: EmployeePage,
        meta: { transition: 'slide-out-left' },
      },
      {
        path: 'logout',
        name: 'LogoutPage',
        component: LogoutPage,
        meta: { transition: 'slide-out-left' },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
