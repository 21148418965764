import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { EmployeeLocked, EmployeeModel } from './models/employee.model';
import { groupBy, sortBy } from 'lodash-es';
import { DEFAULT_LOCALE } from './i18n';

export interface State {
  name: string;
  employees: EmployeeModel[];
  accountId: string;
  locked: Dictionary<EmployeeLocked>;
  locale: string;
  kioskId: string;
}

const getDefaultState = () => {
  return {
    name: '',
    employees: [],
    accountId: '',
    locked: {},
    locale: DEFAULT_LOCALE,
    kioskId: '',
  };
};

export interface DictionaryNum<T> {
  [id: number]: T | undefined;
}
export declare abstract class Dictionary<T> implements DictionaryNum<T> {
  [id: string]: T | undefined;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: getDefaultState(),
  mutations: {
    setAccountId(state: State, accountId: string) {
      state.accountId = accountId;
    },
    setKioskData(state: State, { name, kioskId, locale }) {
      state.name = name;
      state.kioskId = kioskId;
      state.locale = locale;
    },
    setEmployees(state: State, employees: EmployeeModel[]) {
      state.employees = employees;
    },
    setEmployeeLocked(state: State, employee: EmployeeLocked) {
      state.locked[employee.id] = employee;
    },
    logout(state: State) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    getName: (state: State) => {
      return state.name;
    },
    kioskId: (state: State) => {
      return state.kioskId;
    },
    getLockedEmployee: (state: State) => (
      employeeId: string,
    ): EmployeeLocked | undefined => {
      return state.locked[employeeId];
    },
    getEmployees: (state): EmployeeModel[] => {
      const mappedEmployees = state.employees.map((employee: EmployeeModel) => {
        const colorCount = 12;
        const id = parseInt(employee.id, 10);

        return {
          ...employee,
          color: (id % colorCount) + 1,
        };
      });
      return sortBy(mappedEmployees, ['name']);
    },
    getEmployeesGroupedByFirstLetter: (
      state,
      getters,
    ): Dictionary<EmployeeModel[]> => {
      return groupBy(getters.getEmployees, (employee: EmployeeModel) => {
        return employee.name[0].toUpperCase();
      });
    },
    getFirstLetter: (state: State, getters) => {
      const employees = getters.getEmployees;

      return [
        ...new Set(
          employees.map((employee: EmployeeModel) => {
            return employee.name[0].toUpperCase();
          }),
        ),
      ];
    },
  },
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}
