<template>
  <img
    v-if="hasAvatar"
    class="employee-avatar"
    :class="{ 'big-avatar': bigAvatar }"
    :src="avatarUrl"
    @error="hasAvatar = false"
  />
  <span
    v-else
    class="p-sign"
    :class="{
      'big-initials': bigAvatar,
      ['avatar--color-' + employee.color]: true,
    }"
    >{{ employee.initials }}</span
  >
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'EmployeeRow',
  props: {
    employee: {
      type: Object,
      required: true,
    },
    bigAvatar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const avatarUrl = props.bigAvatar
      ? props?.employee?.avatar_large
      : props?.employee?.avatar;
    const hasAvatar = ref(!!avatarUrl);
    return {
      avatarUrl,
      hasAvatar,
    };
  },
});
</script>

<style lang="scss" scoped>
.big-avatar,
.big-initials {
  display: inline-block;
  height: 85px;
  width: 85px;
  font-size: 38px;
  border: 2px solid;
  border-radius: 50%;
  font-weight: 900;
  text-align: center;
  line-height: 87px;
}

.big-avatar {
  margin: 0;
  border-color: white !important;
  object-fit: cover;
}
</style>
