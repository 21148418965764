<template>
  <div class="app-wrapper">
    <div class="app-column welcome-clmn">
      <div class="app-welcome-screen">
        <div class="app-time-widgets employee-time-widget">
          <div class="time-widgets-txt dash-time">
            <DashTime></DashTime>
          </div>
          <div
            class="time-widgets-icon usr-clock-icn back-icon"
            @click="returnToDashboard()"
          >
            <font-awesome-icon icon="arrow-left" />
          </div>
          <div class="time-widgets-icon" style="margin-bottom: -5px">
            <a href="https://www.shiftbase.com/" target="_blank"
              ><img src="../assets/img/logo-neat.svg" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="app-column">
      <div class="logout-screen">
        <h1>&#9888;&nbsp;{{ $t('logout.title') }}</h1>
        <p>{{ $t('logout.confirmation') }}<br />{{ $t('logout.warning') }}</p>
        <Button class="primary-btn btn-warning" @click="logout">
          {{ $t('logout.button') }}
        </Button>
      </div>
    </div>
    <div class="app-closer">
      <svg
        @click="returnToDashboard()"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        class="svg-inline--fa fa-times-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import DashTime from '../components/DashTime.vue';

export default defineComponent({
  name: 'LogoutPage',
  components: {
    DashTime,
  },
  setup() {
    const selectedEmployee = ref(undefined);
    const clockStatus = ref(undefined);
    const timeoutId = ref(null);
    const hideButton = ref(false);
    const transitionName = ref('slow-transition');

    return {
      clockStatus,
      selectedEmployee,
      timeoutId,
      transitionName,
      hideButton,
    };
  },
  methods: {
    returnToDashboard() {
      this.$router.push({
        name: 'Dashboard',
      });
      return;
    },
    logout() {
      localStorage.clear();
      this.$store.commit('logout');
      this.returnToDashboard();
    },
  },
});
</script>

<style lang="scss" scoped>
.logout-screen {
  background-color: #31a7f0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;

  h1,
  p {
    font-weight: bold;
  }

  h1 {
    font-size: 25px;
    border-bottom: 2px solid #2299e3;
    width: 200px;
    margin: 0 auto;

    padding-bottom: 15px;
  }
  p {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 0 20px;
  }
}
</style>
