<template>
  <div class="employee-row">
    <EmployeeAvatar :employee="employee"></EmployeeAvatar>
    <span class="employee-name">{{ employee.name }}</span>
  </div>
  <img src="../assets/img/arrow.svg" alt="" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import EmployeeAvatar from './EmployeeAvatar.vue';

export default defineComponent({
  name: 'EmployeeRow',
  components: {
    EmployeeAvatar,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.employee-row {
  display: flex;
  font-size: 20px;

  .employee-name {
    line-height: 30px;
  }

  span {
    font-weight: 600;
  }
}
</style>
