<template>
  <div class="app-pin-widget">
    <div class="app-pin-info-content">
      <div class="app-pin-txt">
        <div class="pin-tp-img">
          <EmployeeAvatar
            :big-avatar="true"
            :employee="selectedEmployee"
          ></EmployeeAvatar>
        </div>
        <transition name="slide-fade">
          <div v-if="lockedUntilMinutes">
            <h4>{{ $t('employee.lockedOut') }}</h4>
            <p>
              {{
                $t('employee.tryAfter', false, {
                  named: { minutes: lockedUntilMinutes },
                  plural: lockedUntilMinutes,
                })
              }}
            </p>
          </div>
          <div v-else-if="invalidPinCode">
            <h4>{{ $t('employee.invalidPinCode') }}</h4>
            <p>
              {{ $t('employee.retryPinCode', { attempts: pinCodeAttempts }) }}
            </p>
          </div>
          <div v-else>
            <h4>{{ selectedEmployee.name }}</h4>
          </div>
        </transition>
      </div>
      <div class="app-pin-input">
        <input type="text" name="" id="" />
        <div v-if="isLoading" class="dot-flashing"></div>
        <ul v-else>
          <li :class="{ active: pinCode.length > 0 }"></li>
          <li :class="{ active: pinCode.length > 1 }"></li>
          <li :class="{ active: pinCode.length > 2 }"></li>
          <li :class="{ active: pinCode.length > 3 }"></li>
        </ul>
      </div>
    </div>
    <div class="app-pin-button">
      <ul>
        <li v-for="n in 9" :key="n">
          <button @click="select(n)">{{ n }}</button>
        </li>
        <li>
          <button @click="clear()">C</button>
        </li>
        <li><button @click="select(0)">0</button></li>
        <li>
          <button @click="backspace()">
            <font-awesome-icon icon="backspace" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue';
import { AxiosError } from 'axios';
import { addSeconds, differenceInMinutes, isAfter } from 'date-fns';
import { useStore } from '../store';
import EmployeeAvatar from './EmployeeAvatar.vue';

export default defineComponent({
  name: 'EmployeePin',
  components: {
    EmployeeAvatar,
  },
  props: {
    selectedEmployee: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['clocked'],
  setup(props) {
    const pinCode = ref('');
    const invalidPinCode = ref(false);
    const pinCodeAttempts = ref(0);

    const isLoading = ref(false);

    const store = useStore();
    const lockedUntil = computed((): Date | null => {
      const employee = store.getters.getLockedEmployee(
        props.selectedEmployee.id,
      );

      if (!employee) {
        return null;
      }

      if (isAfter(new Date(), employee.until)) {
        return null;
      }

      return employee.until;
    });

    const kioskId = computed(() => {
      return store.getters.kioskId;
    });

    const lockedUntilMinutes = ref(0);

    const updateCurrentTime = () => {
      if (!lockedUntil.value) {
        return;
      }

      const difference = differenceInMinutes(lockedUntil.value, new Date());

      if (difference <= 0) {
        lockedUntilMinutes.value = 0;
        return;
      }

      lockedUntilMinutes.value = difference;
    };

    const intervalHandle = setInterval(updateCurrentTime, 1000);
    onBeforeUnmount(() => {
      clearInterval(intervalHandle);
    });

    return {
      pinCode,
      invalidPinCode,
      pinCodeAttempts,
      lockedUntilMinutes,
      isLoading,
      kioskId,
    };
  },
  methods: {
    select(pin: number) {
      if (
        this.pinCode.length === 4 ||
        this.lockedUntilMinutes ||
        this.isLoading
      ) {
        return;
      }
      this.pinCode = this.pinCode.concat(pin.toString());

      if (this.pinCode.length === 4) {
        this.clock();
      }
    },
    backspace() {
      this.pinCode = this.pinCode.slice(0, -1);
    },
    clear() {
      this.pinCode = '';
      this.isLoading = false;
    },
    async clock() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      try {
        const res = await this.$http.post(
          'kiosk/' + this.kioskId + '/clock',
          undefined,
          {
            headers: {
              authorization: 'PIN ' + this.pinCode,
              'x-app-user': this.selectedEmployee.id,
            },
          },
        );
        this.$emit('clocked', res?.data?.data);
      } catch (err: AxiosError) {
        this.processErrors(err);
      }

      this.clear();
    },
    processErrors(res: AxiosError) {
      switch (res.request.status) {
        case 401: {
          this.invalidPinCode = true;
          this.pinCodeAttempts =
            res.response?.headers['x-auth-attempts-remaining'] ?? 3;
          break;
        }
        case 429: {
          this.locked = true;
          const retryAfter = res.response?.headers['retry-after'] ?? 300;
          this.lockedUntilMinutes = Math.round(retryAfter / 60);
          const until = addSeconds(new Date(), retryAfter);
          this.$store.commit('setEmployeeLocked', {
            id: this.selectedEmployee.id,
            until,
          });
          break;
        }
      }
    },
  },
});
</script>

<style></style>
