<template>
  <div class="app-pin-widget">
    <div class="app-pin-info-content no-pin-code">
      <div class="app-pin-txt">
        <div class="pin-tp-img">
          <EmployeeAvatar
            :big-avatar="true"
            :employee="selectedEmployee"
          ></EmployeeAvatar>
        </div>
        <div>
          <h4>{{ selectedEmployee.name }}</h4>
        </div>
      </div>
    </div>
    <div style="font-size: 18px; text-align: center; color: white">
      <p>{{ $t('employee.noPinCode') }}</p>
      <p v-html="$t('employee.requestPinCode')"></p>
      <br />
      <button @click="refresh" class="refresh-button" type="button">
        {{ $t('employee.refresh') }}&nbsp;
        <font-awesome-icon v-if="!isLoading" icon="sync-alt" />
        <font-awesome-icon v-else class="fa-spin" icon="spinner" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  ref,
} from 'vue';
import { AxiosError, AxiosStatic } from 'axios';
import { addSeconds, differenceInMinutes, isAfter } from 'date-fns';
import { useStore } from '../store';
import { fetchEmployees } from '../shared/kiosk.helper';
import { EmployeeModel } from '../models/employee.model';
import EmployeeAvatar from './EmployeeAvatar.vue';

export default defineComponent({
  name: 'EmployeeNoCredentials',
  components: {
    EmployeeAvatar,
  },
  props: {
    selectedEmployee: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['employeeValidRefresh'],
  setup(props, { emit }) {
    const isLoading = ref(false);
    const axios = <AxiosStatic>getCurrentInstance()?.appContext?.app?.axios;
    const store = useStore();

    const refresh = async () => {
      if (isLoading.value) {
        return;
      }
      isLoading.value = true;
      const data = await fetchEmployees(store.state.kioskId, axios, store);
      if (!!data) {
        const employee = data.find((employee: EmployeeModel) => {
          return employee.id === props.selectedEmployee.id;
        });
        if (employee?.has_credentials) {
          emit('employeeValidRefresh');
        }
      }
      isLoading.value = false;
    };
    return {
      refresh,
      isLoading,
    };
  },
});
</script>

<style>
.refresh-button {
  background: #333c4e;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 25px;
  border-color: transparent;
}
</style>
