import { AxiosError, AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
import { Store } from 'vuex';
import { State } from '../store';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import {
  DEFAULT_LOCALE,
  LANGUAGE_LOCALE_MAP,
  loadLocaleMessages,
} from '../i18n';
import { EmployeeModel } from '../models/employee.model';
import axiosRetry from 'axios-retry';

export const fetchEmployees = (
  kioskId: string,
  axios: AxiosStatic,
  store: Store<State>,
) => {
  return axios
    .get('kiosk/' + kioskId + '/employees')
    .then((res: AxiosResponse) => {
      store.commit('setEmployees', res.data.data);
      return res.data.data;
    })
    .catch((err: AxiosError) => {
      console.error(err.toJSON());
      return [];
    });
};

export const fetchConfig = async (
  kioskShortId: string,
  axios: AxiosStatic,
  store: Store<State>,
  i18n: any,
) => {
  try {
    const res: AxiosResponse = await axios.get(
      'kiosk/' + kioskShortId + '/config',
      { validateStatus: (status) => status !== 401 },
    );

    if (res.status !== 200) {
      return 'server-error';
    }

    const data = res?.data?.data;
    const name = data?.name;
    const locale =
      data?.locale || LANGUAGE_LOCALE_MAP[data?.language] || DEFAULT_LOCALE;
    const kioskId = data?.id;
    await loadLocaleMessages(i18n, locale);
    i18n.locale.value = locale;
    localStorage.setItem('locale', locale);

    store.commit('setKioskData', {
      kioskId,
      name,
      locale,
    });
    return kioskId;
  } catch (e) {
    console.error(e);
    return false;
  }
};
