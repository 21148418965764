import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Store } from 'vuex';
import { State } from '../store';
import { ToastServiceMethods } from 'primevue/toastservice';
import { AppConfigModel } from '../models/app-config.model';
import { I18n } from 'vue-i18n';

export const createAxiosInstance = (
  appConfig: AppConfigModel,
): AxiosInstance => {
  return axios.create({
    baseURL: appConfig.apiUrl,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-app-type': 'kiosk',
      'x-app-version': appConfig.version,
    },
  });
};

export const requestInterceptor = (store: Store<State>) => (
  config: AxiosRequestConfig,
) => {
  config.headers['x-app-account'] = store.state.accountId;
  return config;
};

export const errorInterceptor = (i18n: I18n, toast: ToastServiceMethods) => (
  error: AxiosError,
) => {
  const response = error?.response;
  const message =
    response?.data?.meta?.status_message ||
    (response?.status === 401 && error?.response?.data);

  if (!message) {
    return Promise.reject(error);
  }

  toast.add({
    severity: 'error',
    summary: i18n.global.t('errorTitle'),
    detail: message,
    group: 'br',
    life: 5000,
  });
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
};
