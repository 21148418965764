<template>
  <div
    class="app-clock-blk"
    :class="{ 'bg-red': clockStatus.state === 'CLOCKED_OUT' }"
  >
    <div class="app-clock-txt">
      <div class="app clock-txt-heading">
        <h2>
          {{
            clockStatus.state === 'CLOCKED_IN'
              ? $t('employee.clockedIn')
              : $t('employee.clockedOut')
          }}
        </h2>
        <h2>
          {{ timeWithoutSeconds }}<span> {{ seconds }}</span>
        </h2>
      </div>
      <div class="app-clock-info-txt">
        <h4>{{ employee.name }}</h4>
        <p>{{ clockStatus.team }}, {{ clockStatus.shift }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'EmployeeClocked',
  props: {
    clockStatus: {
      type: Object,
      required: true,
    },
    employee: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const timeWithoutSeconds = ref(props.clockStatus.time.slice(0, 5));
    const seconds = ref(props.clockStatus.time.slice(5, 2));

    return {
      timeWithoutSeconds,
      seconds,
    };
  },
});
</script>

<style></style>
